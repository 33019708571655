<template>
  <div>
    <div class="header_common_box">
        <v-header :params="{ title, leftIcon: true}"></v-header>
    </div>
    <div class="list">
        <div class="item" v-for="(item,index) in userinfo" :key="index">
            <div class="img" @click="gotomyself(item)">
                <img :src="item.postAuthor.avatar_url"/>
            </div>
            <div class="scheme" @click="gotopersonscheme(item)">
                <div class="center">
                    <p style="font-size:0.4rem;margin:0.1rem;">{{item.postAuthor.author}}</p>
                    <p>回报率：{{item.categoryRoi}}%</p>
                </div>
                <div class="last">
                    <div style="display:inline-block">
                        <p class="red">近10中{{item.hitPost}}</p>
                        <p>最高{{item.continuousHit}}连中</p>
                    </div>
                </div>
                <div class="into"><p>></p></div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import vHeader from "@/components/header.vue";
export default {
    data(){
        return{
                title:this.$route.query.title,
                userinfo:JSON.parse(this.$route.query.userinfo)
        }
    },
    components:{
        vHeader
    },
    methods:{
      
        gotopersonscheme(n){
            console.log(n)
            this.$router.push({
                                name:'ranking/personscheme',
                                query:{
                                    title:n.postAuthor.author,
                                    userinfo:JSON.stringify(n),
                                    schemename:this.title
                                }
            })
        },
        //在等接口
        // gotomyself(n){
        //     console.log(n)
        //     this.$router.push({
        //                         name:'ranking/myinfo',
        //                         query:{
        //                             userinfo:JSON.stringify(n)
        //                         }
        //     })
        // }
    },
    mounted(){
        // console.log(JSON.parse(this.$route.query.userinfo))
    },
}
</script>

<style lang="less" scoped>
img{
    padding: 0;
    margin: 0.1rem 0.3rem;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
}
p{
    margin: 0;
    padding: 0;
    font-size: 0.3rem;
    margin:0.1rem;

}
.red{
    color:red;
}
.list{
    background-color: rgba(227, 222, 222, 0.159);
    .item{
        background-color:white;
        margin:0.08rem 0;
        padding: 0.1rem;
        .img{
            width: 15%;
            display: inline-block;
        }
        .scheme{
            width: 84%;
            display: inline-block;
            .center{
                width: 70%;
                display: inline-block;
            }
            .last{
                width: 25%;
                display: inline-block;
            }
            .into{
                
                width: 5%;
                display: inline-block;
                p{
                    font-size: 0.5rem;
                    padding: 0.2rem 0;
                    float: left;
                }
            }
        }
        
    }
}
</style>